import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { polyfill } from 'mobile-drag-drop';
import { isNullOrUndefined } from 'util';

import { AppShared } from './app.shared';
import { User, UserService } from './shared/api/user';
import { HierarchyService } from './shared/api/hierarchy';
import { SubscriptionService } from './shared/utilities/subscription';
import { LocalStorageService } from './shared/services/local-storage-service';
import { SettingsService } from './shared/api/settings';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { Map } from 'immutable';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

  public windowWidth: number;
  public connectionEstablished = false;

  private subscriptionService = new SubscriptionService();

  constructor(public appShared: AppShared,
              private translate: TranslateService,
              private userService: UserService,
              private settingsService: SettingsService,
              private localStorageService: LocalStorageService,
              private hierarchyService: HierarchyService) {

    if (window.location.href.indexOf('preview') !== -1 || window.location.href.indexOf('backend') !== -1) {
      this.translate.use('en');
      this.translate.setDefaultLang('en');
      /* Immediately jump into child component */
      this.connectionEstablished = true;

      if (window.location.href.indexOf('backend') !== -1) {
        /* Setup the entries */
        if (isNullOrUndefined(this.appShared.entryMap)) {
          this.appShared.entryMap = this.generateEntryMap();
        }
      }

      return;
    }

    if (window.location.href.indexOf('/app/') !== -1) {
      this.translate.use('en');
      this.translate.setDefaultLang('en');
      /* Immediately jump into child component */
      this.connectionEstablished = true;

      if (window.location.href.indexOf('backend') !== -1) {
        /* Setup the entries */
        if (isNullOrUndefined(this.appShared.entryMap)) {
          this.appShared.entryMap = this.generateEntryMap();
        }
      }

      return;
    }

    /* Settings */
    this.settingsService.diff.skip(1).take(1).subscribe(_ => {
      this.settingsService.all(false).take(1).subscribe(_ => {

        /* Settings are loaded now we can start to load the rest */
        this.connectionEstablished = true;
        /* Language */
        let lang = <string> localStorageService.get('language');
        if (isNullOrUndefined(lang)) {
          lang = environment.template.defaultLanguage === '' ? 'en' : environment.template.defaultLanguage;
        }
        if (environment.template.language !== '') {
          this.subscriptionService.add('translate', this.translate.currentLoader.getTranslation(lang + '-' + environment.template.language).catch(() => {
            this.afterLanguageHasBeenSet(lang, lang);
            return Observable.empty();
          }).take(1).subscribe(() => {
            this.translate.resetLang(lang + '-' + environment.template.language);
            this.afterLanguageHasBeenSet(lang + '-' + environment.template.language, lang);
            this.appShared.selectedLanguage.next(lang + '-' + environment.template.language);
          }));
        } else {
          this.afterLanguageHasBeenSet(lang, lang);
        }

        /* Setup the entries */
        if (isNullOrUndefined(this.appShared.entryMap)) {
          this.appShared.entryMap = this.generateEntryMap();
        }

        /* Load user */
        userService.load();
      });
    });

    /* Load the settings */
    this.settingsService.load();

    /* Mobile drag and drop support */
    polyfill({ holdToDrag: 300 });
  }

  @HostListener('window:resize', ['$event'])
  public onResize() {
    this.appShared.windowHeight.next(window.innerHeight);
    this.appShared.windowWidth.next(window.innerWidth);
  }

  public ngOnInit() {
    if (window.location.href.indexOf('preview') !== -1) {
      return;
    }
    this.onResize();
    /* Load the assigned nodes */
    this.subscriptionService.add('load.assigned', this.appShared.loadAssigned.subscribe(load => {
      if (load) {
        this.userService.getElementsResponsibleFor();
      }
    }));
    /* Load the hierarchy */
    this.subscriptionService.add('load.hierarchy', this.appShared.loadHierarchy.subscribe(load => {
      if (load) {
        this.hierarchyService.loadAll();
      }
    }));
    /* Set the user */
    this.subscriptionService.add('user', this.userService.find().subscribe((user: User) => {
      this.appShared.user.next(user);
    }));
  }

  public ngOnDestroy(): void {
    this.subscriptionService.remove();
  }

  protected afterLanguageHasBeenSet(language: string, defaultLanguage: string) {
    this.translate.use(language);
    this.translate.setDefaultLang(defaultLanguage);
    this.subscriptionService.add('onLangChange', this.translate.onLangChange.skip(1).subscribe(lang => this.appShared.selectedLanguage.next(lang)));
  }

  private generateEntryMap() {
    let entryMap = Map<string, Map<string, any>>();
    Map(environment.template).forEach((obj, key) => {
      if (obj instanceof Object && !isNullOrUndefined(obj.tabs)) {
        entryMap = entryMap.set(key, this.getEntryMap(obj.tabs));
      }
    });
    return entryMap;
  }

  private getEntryMap(entries: any[], result = Map<string, any>()): Map<string, any> {
    const count = entries.length;
    for (let i = 0; i < count; i++) {
      const entry = entries[i];
      if (!isNullOrUndefined(entry.entry)) {
        result = result.set(entry.entry.key, entry.entry);
      }
      if (!isNullOrUndefined(entry.children)) {
        result = this.getEntryMap(entry.children, result);
      }
    }
    return result;
  }

}
