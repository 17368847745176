import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { BaseModule } from './base.module';
import { AppShared } from './app.shared';
import { SharedModule } from './shared';
import { LocalStorageModule } from './shared/services/local-storage-service';
import { ErrorReporter } from './error.reporter';
import { NotifyService } from './shared/notifications/notification.service';
import { ThirdPartyModule } from './shared/services/thirdparty-service/thirdparty.module';
import { LibraryService } from './shared/services/library/library.service';
import { ModalModule } from 'ngx-bootstrap';
import { AppGlobal } from './app.global';
import { CalculationService } from './services/calculation/calculation.service';
import { CollectorService } from './services/collector/collector.service';
import { ImportService } from './services/data/import.service';
import { ExportService } from './services/data/export.service';
import { MainComponent } from './main/main.component';

const routes: Routes = [
  // { path: '', redirectTo: 'preview', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./main/main.module').then(m => m.MainModule) },
  { path: 'preview', loadChildren: () => import('./main/main.module').then(m => m.MainModule) },
  { path: 'dashboard', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'instance/:instanceID', loadChildren: () => import('./organisation/organisation.module').then(m => m.OrganisationModule) },
  { path: 'instance/:instanceID/businessarea/:businessareaID', loadChildren: () => import('./organisation/organisation.module').then(m => m.OrganisationModule) },
  { path: 'working', loadChildren: () => import('./working/working.route.module').then(m => m.WorkingRouteModule) },
  { path: 'import', loadChildren: () => import('./import/import.module').then(m => m.ImportModule) },
  { path: 'edit', loadChildren: () => import('./edit/edit.module').then(m => m.EditModule) },
  { path: 'export', loadChildren: () => import('./export/export.module').then(m => m.ExportModule) },
  { path: 'backend', loadChildren: () => import('./backend/backend.route.module').then(m => m.BackendRouteModule) },
  { path: 'logout', loadChildren: () => import('./screens/logout/logout.screen.module').then(m => m.LogoutScreenModule) },
  { path: 'app', loadChildren: () => import('./screens/app/app.screen.module').then(m => m.AppScreenModule) },
];

@NgModule({
  providers: [
    NotifyService,
    LibraryService,
    ErrorReporter,
    AppShared,
    AppGlobal,
    CalculationService,
    CollectorService,
    ImportService,
    ExportService
  ],
  declarations: [
    AppComponent
  ],
  imports: [
    RouterModule.forRoot(routes),
    BrowserModule,
    BaseModule,
    LocalStorageModule.withConfig({
      prefix: 'valueminer',
      storageType: 'localStorage'
    }),
    SharedModule,
    ThirdPartyModule,
    ModalModule.forRoot()
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
