import { Action } from '../shared/common.action';
import { GeneralAction } from './general.actions';
import { GeneralState } from './general.models';
import { Utils } from '../shared';

export default function reducer(state = new GeneralState(), action: Action): GeneralState {

  switch (action.type) {

    case GeneralAction.GET_HEALY_MEMBERS: {
      return <GeneralState> state.withMutations(s => s
        .updateIn(['isBusy'], () => 1)
      );
    }
    case GeneralAction.GET_HEALY_MEMBERS_SUCCESS:
      return <GeneralState>Utils.updateGeneralState<GeneralState>(state, 'healyMembers', action.payload)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    case GeneralAction.GET_HEALY_MEMBERS_FAILED: {
      return <GeneralState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case GeneralAction.UPLOAD_DOCUMENT: {
      return <GeneralState> state.withMutations(s => s
        .updateIn(['isBusy'], () => 1)
      );
    }
    case GeneralAction.UPLOAD_DOCUMENT_SUCCESS:
      return <GeneralState>Utils.updateDiff(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    case GeneralAction.UPLOAD_DOCUMENT_FAILED: {
      return <GeneralState>Utils.updateDiff(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case GeneralAction.GET_DOCUMENTS: {
      return <GeneralState> state.withMutations(s => s
        .updateIn(['isBusy'], () => 1)
      );
    }
    case GeneralAction.GET_DOCUMENTS_SUCCESS:
      return <GeneralState>Utils.updateDiff(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    case GeneralAction.GET_DOCUMENTS_FAILED: {
      return <GeneralState>Utils.updateDiff(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    default:
      return state;
  }
}
